import * as React from 'react'
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'

import Vid from '../../media/videos/location_flansham.mp4'
import VidPoster from '../../media/videos/location_flansham_poster.jpg'

import HeaderVideo from '../headerVideo'

// import components
import LocAnimatedSection from './locAnimatedSection'

import { fontSize } from '../../css/mixins/mixins'

import styled from 'styled-components'

const Breadcrumb = styled.div`
  background-color: whitesmoke;
  padding: 10px 0;
  margin-bottom: 60px;
`

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  ${fontSize(15)}
  transition: 0.3s color;

  &:hover {
    color: #3a3a3a;

    i {
      border-color: #3a3a3a;
    }
  }
`

const BreadcrumbArrow = styled.i`
  border: solid #917147;
  transition: 0.3s border-color;

  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-right: 5px;
`

const PageTitleContainer = styled.div`
  position: relative;
  padding-top: 60px;
  margin-bottom: 50px;

  @media only screen and (min-width: 768px) {
    padding-top: 120px;
  }

  &::before {
    content: '';
    height: 5px;
    width: 300px;
    background-color: #1f1f1f;
    position: absolute;
    top: 30px;

    @media only screen and (min-width: 768px) {
      top: 60px;
    }
  }
`

const MainTitle = styled.h1`
  opacity: 0;
  transform: translateX(-40px);
  transition: 1s opacity ease-in-out, 1s transform ease-in-out;

  &.active {
    opacity: 1;
    transform: translateX(0px);
  }
`

const SecondaryTitle = styled.h2`
  opacity: 0;
  transform: translateX(-40px);
  transition: 1s opacity ease-in-out, 1s transform ease-in-out;
  transition-delay: 0.45s;
  &.active {
    opacity: 1;
    transform: translateX(0px);
  }
`

const VideoContainer = styled.div`
  background-color: whitesmoke;
  line-height: 0;
  padding: 0;
  margin: 40px 0;

  opacity: 0;
  transition: 1s opacity ease-in-out;
  transition-delay: 0.45s;

  &.active {
    opacity: 1;
  }
`

const VideoRatio = styled.div`
  position: relative;
  padding-top: 52.8%;
`

const IframeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const LocFlansham = ({ data }) => {
  const { title = '', description, main_image = require(`../../media/images/basic_layout_placeholder_image_1.png`).default } = data

  const url = main_image !== null ? main_image?.url || main_image : require(`../../media/images/basic_layout_placeholder_image_1.png`).default

  const { ref, inView, entry } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })

  return (
    <>
      <HeaderVideo src={Vid} poster={VidPoster} title={title} />
      <Breadcrumb>
        <div className="container">
          <BreadcrumbLink to="/location">
            <BreadcrumbArrow /> Back to locations
          </BreadcrumbLink>
        </div>
      </Breadcrumb>
      <PageTitleContainer className="container" ref={ref}>
        <MainTitle className={inView ? ' active' : ''}>Nestled in an exclusive hamlet in West Sussex</MainTitle>
        <SecondaryTitle className={inView ? ' active' : ''}>
          Our premium barn conversion offers the latest in Nohrd beautifully crafted equipment for personal training sessions as well as a range of bespoke fitness classes.
        </SecondaryTitle>
      </PageTitleContainer>

      <VideoContainer className={inView ? ' active' : ''}>
        <div className="container">
          <VideoRatio>
            <IframeContainer>
              <iframe
                width={'100%'}
                height={'100%'}
                src={'https://www.youtube.com/embed/flMBvBUDbwI?rel=0'}
                title="Ty Paul Private Studio Loction Hove"
                frameBorder="0"
                allow="accelerometer;"
                allowFullScreen
              ></iframe>
            </IframeContainer>
          </VideoRatio>
        </div>
      </VideoContainer>

      {pageData.map((item, index) => {
        const { text, image, dir, id } = item
        return <LocAnimatedSection text={text} image={image} dir={dir} key={id} />
      })}
    </>
  )
}

export default LocFlansham

const pageData = [
  {
    id: 1,
    text: 'We specialise in luxury fitness and offer our clients only the highest qualified personal trainers and expertly crafted equipment in order to maximise results on your fitness investment.',
    image: require(`../../media/images/flansham_photo_1.jpg`).default,
    dir: 'left',
  },
  {
    id: 2,
    text: 'After filling out the online health screening form and phone consultation your personal training plan will be devised. This plan will project your goals and targeted achievements in a bespoke session plan which will then be cast on the big screen in our state of the art barn conversion, set to a backdrop of your own music playlist. These tailored session plans will adapt each week to stay in line with your fitness progressions.',
    image: require(`../../media/images/flansham_photo_2.jpg`).default,
    dir: 'right',
  },
  {
    id: 3,
    text: 'During your hour Personal Training sessions a range of innovative treatments and techniques will be incorporated. Theragun percussive therapy is available. This treatment impacts the muscle with a targeted, scientifically calibrated dose for greater therapeutic benefits for the body which aids recovery. You will not only have the endorphin release which you will experience after each session but you will also leave feeling refreshed and revitalised.',
    image: require(`../../media/images/flansham_photo_3.jpg`).default,
    dir: 'left',
  },
  {
    id: 4,
    text: 'The gym is fully equipped with not only the beautifully crafted NOHRD equipment but the latest cutting technology such as blaze pods, Samsung Sero, heroboard, eau-me boards and a stunning leather punch bag.',
    image: require(`../../media/images/flansham_photo_4.jpg`).default,
    dir: 'right',
  },
  {
    id: 5,
    text: "Ty Paul was voted Britain's Best Coach by Men's Health magazine, a fitness expert for Men's Fitness magazine, advisor to Mr Porter, specialist trainer to Balance magazine and personal trainer to Premier League and Women's Super League players such as Marc Cucurella, Yves Bissouma, Julio Enciso, Moises Caicedo & Maya Le Tissier. Ty has also helped actresses from Downton Abbey to comedians such as Angela Barnes.",
    image: require(`../../media/images/flansham_photo_5.jpg`).default,
    dir: 'left',
  },
  {
    id: 6,
    text: 'Ty Paul is also a NHS personal trainer who specialises in rehabilitation and with over 16 years experience training clients of all ages and fitness levels, your sessions at Ty Paul’s studio are guaranteed to be varied and challenging - keeping you motivated whilst you achieve results.',
    image: require(`../../media/images/flansham_photo_6.jpg`).default,
    dir: 'right',
  },
]
