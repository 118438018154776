import * as React from 'react'
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'

import Vid from '../../media/videos/location_hove.mp4'
import VidPoster from '../../media/videos/location_hove_poster.jpg'

import HeaderVideo from '../headerVideo'
import LocAnimatedSection from './locAnimatedSection'

import styled from 'styled-components'
import { fontSize } from '../../css/mixins/mixins'
const Breadcrumb = styled.div`
  background-color: whitesmoke;
  padding: 10px 0;
  margin-bottom: 60px;
`

const BreadcrumbLink = styled(Link)`
  text-decoration: none;
  ${fontSize(15)}
  transition: 0.3s color;

  &:hover {
    color: #3a3a3a;

    i {
      border-color: #3a3a3a;
    }
  }
`

const PageTitleContainer = styled.div`
  position: relative;
  padding-top: 60px;
  margin-bottom: 50px;

  @media only screen and (min-width: 768px) {
    padding-top: 120px;
  }

  &::before {
    content: '';
    height: 5px;
    width: 300px;
    background-color: #1f1f1f;
    position: absolute;
    top: 30px;

    @media only screen and (min-width: 768px) {
      top: 60px;
    }
  }
`

const MainTitle = styled.h1`
  opacity: 0;
  transform: translateX(-40px);
  transition: 1s opacity ease-in-out, 1s transform ease-in-out;

  &.active {
    opacity: 1;
    transform: translateX(0px);
  }
`

const SecondaryTitle = styled.h2`
  opacity: 0;
  transform: translateX(-40px);
  transition: 1s opacity ease-in-out, 1s transform ease-in-out;
  transition-delay: 0.45s;
  &.active {
    opacity: 1;
    transform: translateX(0px);
  }
`

const BreadcrumbArrow = styled.i`
  border: solid #917147;
  transition: 0.3s border-color;

  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-right: 5px;
`

const VideoContainer = styled.div`
  background-color: whitesmoke;
  line-height: 0;
  padding: 0;
  margin: 40px 0;
`

const VideoRatio = styled.div`
  position: relative;
  padding-top: 52.8%;
`

const IframeContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const LocHove = ({ data }) => {
  const { title = '', description, main_image = require(`../../media/images/basic_layout_placeholder_image_1.png`).default } = data

  const url = main_image !== null ? main_image?.url || main_image : require(`../../media/images/basic_layout_placeholder_image_1.png`).default

  const { ref, inView, entry } = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })
  return (
    <>
      <HeaderVideo src={Vid} poster={VidPoster} title={title} />
      <Breadcrumb>
        <div className="container">
          <BreadcrumbLink to="/location">
            <BreadcrumbArrow /> Back to locations
          </BreadcrumbLink>
        </div>
      </Breadcrumb>
      <PageTitleContainer className="container" ref={ref}>
        <MainTitle className={inView ? ' active' : ''}>The best luxury one on one personal training service in Brighton & Hove.</MainTitle>
        <SecondaryTitle className={inView ? ' active' : ''}>A luxury state of the art one-on-one studio designed to give you your bespoke workout in absolute privacy.</SecondaryTitle>
      </PageTitleContainer>
      <VideoContainer>
        <div className="container">
          <VideoRatio>
            <IframeContainer>
              <iframe
                width={'100%'}
                height={'100%'}
                src={'https://www.youtube.com/embed/aWbZjCwzaNM?rel=0'}
                title="Ty Paul Private Studio Loction Hove"
                frameBorder="0"
                allow="accelerometer;"
                allowFullScreen
              ></iframe>
            </IframeContainer>
          </VideoRatio>
        </div>
      </VideoContainer>
      {pageData.map((item, index) => {
        const { text, image, dir, id } = item
        return <LocAnimatedSection text={text} image={image} dir={dir} key={id} />
      })}
    </>
  )
}

export default LocHove

const pageData = [
  {
    id: 1,
    text: "Ty Paul Fitness Hove regularly features Men's Health Magazine named Britains Best Personal Training Service and is also home to Brighton & Hove Albion and Chelsea football players such as Marc Cucurella, Moises Ceicedo and Julio Enciso.",
    image: require(`../../media/images/hove_photo_1.jpg`).default,
    dir: 'left',
  },
  {
    id: 2,
    text: 'The Hove studio is located at the top of Boundary Road and easily accessible via car with ample surrounding parking, by bus or a 30 second walk from Portslade Train Station.',
    image: require(`../../media/images/hove_photo_2.jpg`).default,
    dir: 'right',
  },
  {
    id: 3,
    text: 'Personal Training sessions can be booked with either studio owner Ty or one of the highly qualified team members.',
    image: require(`../../media/images/hove_photo_3.jpg`).default,
    dir: 'left',
  },
  {
    id: 4,
    text: 'Ty Paul Fitness Hove is fully equiped with the luxury Nohrd brands Sprintbok, Slimbean & Eau-Me. It also houses the Samsung Sero where all of your tailored workouts will be projected onto while working out to your personalised playlist.',
    image: require(`../../media/images/hove_photo_4.jpg`).default,
    dir: 'right',
  },
]
