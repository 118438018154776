import * as React from 'react'
import { fontSize } from '../css/mixins/mixins'

import styled, { keyframes } from 'styled-components'
const fadeout = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`
const VideoWrap = styled.section`
  background-color: #372c2e;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 6px 29px 1px rgba(0, 0, 0, 0.5);
  line-height: 0;

  @media only screen and (min-width: 1024px) {
    height: 100vh;
  }
`

const Video = styled.video`
  opacity: 0.4;

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }

  @media only screen and (min-width: 1024px) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 110%;
  }
`

const Title = styled.h1`
  position: absolute;
  top: 50%;
  animation: 1s ${fadeout} forwards ease-out;
  animation-delay: 2s;
  left: 50%;
  color: #fff;
  min-width: 200px;
  text-align: center;
  padding: 15px 30px;
  transform: translate(-50%, -50%);
  font-weight: 300;
  background-color: transparent;
  width: 100%;
  max-width: 90%;
  ${fontSize(35)}

  @media only screen and (min-width: 930px) {
    left: calc(50% + 55px);
    ${fontSize(50)}
  }

  &::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
  }
`

const HeaderVideo = ({ src, poster, title = '' }) => {
  return (
    <VideoWrap id="header-target">
      <Video loop autoPlay={true} playsInline muted poster={poster}>
        <source src={src} type="video/mp4" />
      </Video>
      {title !== '' && <Title>{title}</Title>}
    </VideoWrap>
  )
}

export default HeaderVideo
